<template>
  <div class="page_wrapper">
    <div class="fix_head">
      <a href="#" @click="$router.back()">&lt; 返回</a>
      产品列表
    </div>
    <div class="detail_box">
      <div class="cell_line" v-for="(item, index) in serviceInfo.insuranceDetails" :key="index">
        <div class="line">
          <div class="name">产品名称</div>
          <div class="value_box" id="name"> {{ item.productName || '--' }}</div>
        </div>
        <div class="line">
          <div class="name">服务凭证</div>
          <div class="value_box" id="cardNo">{{ item.insuranceNo || '--' }}</div>
        </div>
        <div class="line">
          <div class="name">保险期限</div>
          <div class="value_box" id="standNo">{{ `${item.effectiveTime}时至${item.terminationTime}时止` || '--' }}</div>
        </div>
        <div class="div_btn">
          <van-button class="query" round block type="info" native-type="submit" @click="goto(item, index)" v-if="!isClaim">查看详情</van-button>
          <van-button class="query" round block type="info" native-type="submit" @click="goto(item, index, '/verification/orderDetails')" v-if="!isOrder">工单详情</van-button>
          <van-button class="query" round block type="info" native-type="submit" @click="goto(item, index, '/verification/claimApply')" v-if="isClaim">理赔申请</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getDetail } from '@/api/verification'
  export default {
    name: 'ServiceDetail',
    data() {
      return {
        keyword: '',
        serviceInfo: {},
        type: '',
        footerInfo: { "type": "telphone", "label": "保险公司验真咨询电话:", "content": "95500" },
        isClaim: ''
      }
    },
    async created() {
      this.keyword = this.$route.query.keyword
      // this.isPicc = this.$route.query.type === 'picc'
      this.isClaim = this.$route.query.isClaim || ''
      if (!this.keyword) {
        this.$toast.fail('查询保单号为空')
        return
      }
      const resp = await getDetail({ keyword: this.keyword })
      if (!resp.success) {
        this.$toast.fail(resp.respMag)
        return
      }
      const serviceInfo = resp.content
      this.serviceInfo = serviceInfo
    },
    methods: {
      goto(item, index, url){
        this.$router.push({ path: url || '/verification/detail', query: { keyword: item.insuranceNo, index: index } })
      },
    }
  }
</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

  body,
  html {
    background: #f2f2f2;
  }

  .cell_group {
    padding: 10px;
  }

  .official {
    padding: 10px;
    display: flex;
    margin-bottom: 30px;
  }

  .official .picc {
    width: 120px;
  }

  .page_wrapper {
    padding-top: 40px;
  }

  .fix_head {
    background: #ED5D43;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0 10px;
    text-align: center;
  }

  .fix_head a {
    color: #fff;
    text-decoration: none;
    position: absolute;
    height: 100%;
    width: 60px;
    left: 0px;
  }

  .cell_line {
    background: #fff;
    margin: 10px 0;
    padding: 0 10px;
    /* border-bottom: 1px solid #999; */
  }

  .cell_line .h2 {
    padding: 10px;
    color: #ED5D43;
    font-weight: normal;
    border-bottom: 1px solid #f2f2f2;
    background: url(../../assets/official-account/i_02.png) no-repeat left center;
    background-size: 20px auto;
    padding-left: 24px;
  }

  .cell_line .i_01 {
    background: url(../../assets/official-account/i_01.png) no-repeat left center;
    background-size: 20px auto;
    padding-left: 24px;
  }

  .line {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px;
  }

  .line .name {
    width: 90px;
  }

  .line .value_box {
    flex: 1;
    text-align: right;
  }

  .official .official-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
  }

  .official .official-remak {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy {
    border: none;
    padding: 2px 8px;
    margin-left: 5px;
    background-color: #999;
    color: #fff;
    width: 50px;
    line-height: 22px;
    height: 22px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
  }

  .official-qh {
    padding: 40px 20px;
    text-align: center;
  }

  .color9 {
    color: #999;
  }

  .div_btn {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
  }

  .div_btn .query {
    background: #ED5D43;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    width: 60%;
    border: 0;
    margin: auto;
  }
</style>
